import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./AreaGuideHeader.scss"

const AreaGuideHeader = props => {
  const [active, setActive] = useState(0)

  const [scrollPosition, setScrollPosition] = useState(0)
  const [scrollResult, setScrollResult] = useState("")

  const { isTabletOnly, isMobile } = useDeviceMedia()

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        const position = window.scrollY
        if (position > 630 && isTabletOnly) {
          setScrollResult("top-position")
        } else if (position > 537 && isMobile) {
          setScrollResult("top-position")
        } else if (position > 660) {
          setScrollResult("top-position")
        } else {
          setScrollResult("")
        }
        setScrollPosition(position)
      }

      window.addEventListener("scroll", handleScroll)

      return () => window.removeEventListener("scroll", handleScroll)
    }
  }, [isTabletOnly, isMobile])

  let menus = []

  if (props.area) {
    props.idArray?.filter(data => {
      if (data === "overview") {
        menus.push({ label: "Overview", id: "overview", key: "overview_" })
      }
      if (data === "video") {
        menus.push({
          label: "Video",
          id: "video",
          key: "video_",
        })
      }
      if (data?.includes("_two-block-item")) {
        const splitItem = data?.split("_");
        const idData = splitItem?.length>0?splitItem[0]:""
        menus.push({
          label: idData?.replace(/-/g," "),
          id: data,
          key: `${idData}_`,
        })
      }
      // if (data === "Property") {
      //   menus.push({
      //     label: "Property",
      //     id: "Property",
      //     key: "Property_",
      //   })
      // }
      // if (data === "Activities") {
      //   menus.push({
      //     label: "Activities",
      //     id: "Activities",
      //     key: "Activities_",
      //   })
      // }
      if (data === "faqs") {
        menus.push({
          label: "FAQ's",
          id: "faqs",
          key: "faqs_",
        })
      }
      if (data === "map") {
        menus.push({
          label: "Map",
          id: "map",
          key: "map_",
        })
      }
    })
  }

  // const handleLink = (id, index, key) => {
  //   const element = document.getElementById(id)
  //   element&&element.scrollIntoView({ behavior: "smooth" })
  // } 
  const handleLink = (id, index, key) => {
    const element = document.getElementById(id)
    if (element) {
      const elementStyle = window.getComputedStyle(element);
      const marginTop = parseFloat(elementStyle.marginTop);
      const paddingTop = parseFloat(elementStyle.paddingTop);
      const findOffset = element.offsetTop - marginTop - paddingTop;
      window.scrollTo({
        top: findOffset-110,
        behavior: "smooth"
      });
    }
    
  }



  const handleScrolling = () => {
    const navLinks = menus.slice(0)
    const sectionEl = document.querySelectorAll(".section_items")
    let currentSection = "overview"
    if (sectionEl) {
      sectionEl.forEach(ele => {
        if (window.scrollY >= parseInt(ele.offsetTop) - 150) {
          currentSection = ele.id
        }
      })

      navLinks.forEach((link, index) => {
        if (currentSection) {
          if (link.id?.includes(currentSection)) {
            setActive(index)
          }
        }
      })
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScrolling)
  }, [isTabletOnly, isMobile])

  return (
    <div className={`component-scroll-wrapper ${scrollResult}`} id="scroll-cmp">
      <Container>
        <div className={`scroll-to-component area`}>
          {menus &&
            menus.map((menu, index) => {
              return (
                <div
                  className={`list-item ${active === index ? "active" : ""}`}
                >
                  <a onClick={() => handleLink(menu?.id, index)}>{menu?.label}</a>
                </div>
              )
            })}
        </div>
      </Container>
    </div>
  )
}

export default AreaGuideHeader
