import * as React from "react"
// import ScrollAnimation from "react-animate-on-scroll"
// import GoogleSingleMap from "../../components/map/google/single"
import GoogleSingleMap from "../../components/maps/google/single"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./Map.scss"
// styles

// markup
const Map = props => {
  const { isMobile } = useDeviceMedia()
  return (
    props?.property_details_map_data && (
      <React.Fragment>
        <div className="property-details-map-wrap" id="map-component">
          {/* <ScrollAnimation animateIn="fadeIn" animateOnce={true}> */}
          {props?.property_details_map_data?.latitude &&
            props?.property_details_map_data?.longitude && (
              <GoogleSingleMap
                className="gg-map"
                lat={props?.property_details_map_data?.latitude}
                lng={props?.property_details_map_data?.longitude}
                height={
                  isMobile
                    ? "240px"
                    : props?.property_details_map_data?.department ===
                      "new_developments"
                    ? "480px"
                    :
                    props?.areaGuide?"600px"
                    : "460px"
                }
              />
            )}
          {/* </ScrollAnimation> */}
        </div>
      </React.Fragment>
    )
  )
}

export default Map
