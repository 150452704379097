import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import FormFields from "../../../static/forms/areaGuide.json"
import DefaultForm from "../forms/default-form-layout"
import "./ContactComponent.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const ContactComponent = ({ pageData,isPreviewEnabled }) => {
  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  

  const whatsappNum = pageData?.whatsapp_number
    ? pageData?.whatsapp_number
    : "971502304695"


  const text = `Hi, I saw this property on haus & haus ${pageurl} and would like to know more.`


  return (
    <div className={`contact-details-component`} >
      <ScrollAnimation
        animateIn="animate__slideInUp"
        animateOnce
        delay={100}
        offset={100}
      >
        <Container>
          <div className="contact-sections">
            <div className="content-sections">
              <h4>{pageData?.form_title}</h4>
              {(pageData?.description?.data?.description ||(isPreviewEnabled&&pageData?.description))&&
              <p className="contact-intro">
                <ContentModule
                  Content={pageData?.description?.data?.description||pageData?.description}
                />
              </p>
              }
              <div className="social-details">
                <div>
                  <a
                    href={`https://wa.me/${whatsappNum?.replace(/[+\s]/g, '')}?text=${encodeURIComponent(text)}`}
                    target="_blank"
                  >
                    <icon className="icon green-whatsapp-icon" />
                    <span className="whatsapp">Click to WhatsApp</span>
                  </a>
                </div>
                <div className="horizontal-line"></div>
                <div>
                  <a className="email-id" href={`mailto:info@hausandhaus.com`}>
                    <icon className="icon green-envelop-icon" />
                    <span>info@hausandhaus.com</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="form-section__footer">
              <DefaultForm
                fields={FormFields}
                sourceUrl={pageurl}
                pageTitle={pageData.title}
                emailSubUser={`${pageData.title} area guide enquiry`}
                emailSubAdmin = {`${pageData.title} area guide enquiry`}
              />
            </div>
            
          </div>
        </Container>
      </ScrollAnimation>
    </div>
  )
}

export default ContactComponent